/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.full-table-height {
    height: calc(100vh - 176px);
}

@media (max-width: 768px) {
    .full-table-height {
        height: calc(100vh - 210px);
    }
}

.scroll-custom::-webkit-scrollbar {
    width: 8px;
}

.scroll-custom::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
  
.scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: rgba(17, 24, 39, .25);
}